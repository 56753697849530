

    <template>
        <AnalyticsCard layout="vertical" :title="title" :chart-height="chartHeight" :showLoader="isLoading">
            <template #chart>
                <PieChart  
                    v-if="!isLoading && options.length && chart.datasets.length && chart.datasets[0].data.length"
                    :datasets="chart.datasets"
                    :labels="chart.labels"
                    show-legend
                    :dashed-lines="true"
                    :aspect-ratio="aspectRatio"
                    :chart-options="chartOptions"
                    />
                <div
                    v-if="!isLoading && (!options.length || chart.datasets.length && !chart.datasets[0].data.length)"
                    class="!min-h-[13rem] flex font-bold text-xl">
                    <div class="m-auto"> <Txt bold>{{ $t('Dashboard.Charts.NoData') }}</Txt> </div>
                </div>
            </template>
            <template v-if="options.length" #menu-items>
                <MenuItem v-for="opt in options" :key="opt.key" @click="showData(opt)">{{opt.label || opt.key}}</MenuItem>
            </template>
        </AnalyticsCard> 
    </template> 
    
<script>
import phishingService from '@/services/phishing.service.js'
import colors from '@/utils/colors'

export default {
    data() {
        return {
            isLoading: false,
            data: {},
            title: this.$t("Dashboard.Charts.RiskByTagsDefault"),
            chart : {},
            chartOptions: {},
            counter: 0
        }
    },
    props: {
        chartHeight: Number,
        aspectRatio : Number,
        options : Array,
        maxSliceSize: Number,
        apiFilter: Object
    },
    watch: {
        '$props.apiFilter': {
            handler() {
                this.loadData()
            },
            deep: true
        }
    },
    beforeMount(){
        this.initDataSet()
    },
    mounted(){
        this.loadData()
    },
    methods : {
        loadData(){
            this.data = []
            const team = this.options && this.options.length > 0 && this.options.find(o=>o.team)
            this.showData(team || this.options[0])
        },
        initDataSet(){
            this.chart = {
                labels: [],
                datasets: [
                    {
                    label: '',
                    backgroundColor: [
                        colors.green[500], colors['soft-blue'][500], colors.accent[500] , colors.violet[500], colors.primary[500],colors.red[500] ,
                        colors.violet[300], colors.primary[300] ,colors.red[300], colors.green[300], colors['soft-blue'][300], colors.accent[300] ,
                    ] ,
                    clicked : [],
                    sent : [],
                    data: [],
                    },
                ],
            }
        },
        showData(tag){
            this.title = this.$t("Dashboard.Charts.RiskByTags", { tag: tag?.label || tag?.key || '-'})
            if (!tag) return;

            this.initDataSet();
            this.chart.datasets[0].label=tag.label || tag.key
            this.transformData(tag.key)
            this.$forceUpdate()
        },
        async transformData(tag){
            try {
                this.counter++;
            
                this.isLoading = true;
                
                if(!this.data[tag]){
                    const result = await phishingService.getCompanyRiskByTag(this.$currentCompany.company_id, tag, this.apiFilter)
                    this.data[tag] = result.data || []
                }
                let d = [...this.data[tag]].slice()

                // if maxSliceSize has been set and the length is greater than the property, group all small entries in one slice named 'other'
                if (this.maxSliceSize && d.length > this.maxSliceSize) {
                    d = d.sort((a, b) => b.n_clicked - a.n_clicked);

                    let otherEntries = { 
                        tag: this.$t('Roles.Other'),
                        n_sent: 0, 
                        n_clicked: 0
                    }; 
                    
                    d.slice(this.maxSliceSize-1).forEach((d) => {
                        otherEntries.n_sent += d.n_sent;
                        otherEntries.n_clicked += d.n_clicked;
                    })
                    d = [...d.slice(0, this.maxSliceSize-1), otherEntries];
                }

                this.chart.datasets[0].label = tag
                let totalClicks = 0
                for(const key of d ){
                    // rename null tag to the default 'Not Assigned' label
                    if (!key.tag) key.tag = this.$t('Analytics.NA');
                    totalClicks+=key.n_clicked
                }
                for(const key of d ){
                    if(key.tag){
                        this.chart.datasets[0].data.push(totalClicks? ((key.n_clicked/totalClicks)*100).toFixed(1) : 100)
                        this.chart.datasets[0].sent.push( key.n_sent)
                        this.chart.datasets[0].clicked.push( key.n_clicked)
                        this.chart.labels.push( key.tag )
                    }
                }
                this.setOptions()
            } catch (error) {
				this.$eventBus.$emit('show-alert', {title: this.$t('General.DataFetchError'), variant: 'danger'});
			} finally {
				if (this.counter > 0) this.counter--;
                
				if (!this.counter) this.isLoading = false;
			}
        },
        setOptions(){
            this.chartOptions = {
                plugins: {
                    tooltip: {
                        callbacks : {
                            label: (tooltipItem) => {
                                return `${tooltipItem.label}: ${tooltipItem.formattedValue}%`;
                            },
                            afterBody: (tooltipItem) => {
                                let body = []
                                let index = tooltipItem[0].dataIndex;
                                let sent = tooltipItem[0].dataset.sent[index];
                                let clicked = tooltipItem[0].dataset.clicked[index];
                                body.push("");
                                body.push(this.$t('Dashboard.Charts.AttacksSent') + ": " + sent);
                                body.push(this.$t('Dashboard.Charts.TotalClicks') + ": " + clicked);
                                
                                return body;
                            }
                        }
                    }
                }
            }
        }
    }
};
</script>