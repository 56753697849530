<template>
  <ChartJSLine
    ref="chart"
    :class="{
      'pointer-events-none': noInteractions,
    }"
    :title="title"
    :chart-data="chartData"
    :dataset-id-key="datasetIdKey"
    :chart-options="config"
    :styles="{ width: '100%', height: '100%' }"
    :width="0"
    :height="0"
    tabindex="0"
    :aria-label="title"
    role="graphics-object"
    :aria-roledescription="chartDescription"
  />
</template>

<script>
import { Line as ChartJSLine } from 'vue-chartjs/legacy'
import { mergeObjects } from '@/utils/object'
import '@/utils/charts'

export default {
  components: { ChartJSLine },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    chartOptions: {
      type: Object,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      default: undefined,
    },
    showXLabels: {
      type: Boolean,
      default: false,
    },
    showYLabels: {
      type: Boolean,
      default: false,
    },
    showXBorder: {
      type: Boolean,
      default: false,
    },
    showYBorder: {
      type: Boolean,
      default: false,
    },
    showXIntraLines: {
      type: Boolean,
      default: false,
    },
    showYIntraLines: {
      type: Boolean,
      default: false,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    dashedLines: {
      type: Boolean,
      default: false,
    },
    noInteractions: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: undefined,
    },
    ticksColor: {
      type: String,
      default: "black"
    } 
  },
  data() {
    return {
      renderComponent: false,
    }
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets.map((dataset) =>
          mergeObjects({ tension: 0.3, pointRadius: 0, pointHoverRadius: 0 }, dataset)
        ),
      }
    },
    config() {
      const defaultConfig = {
        responsive: true,
        aspectRatio: this.aspectRatio,
        maintainAspectRatio: !!this.aspectRatio,
        plugins: {
          title: {
            display: !!this.title,
            text: this.title,
            padding: {
              bottom: 20
            },
          },
          legend: {
            display: this.showLegend,
          },
        },
        scales: {
          x: {
            grid: {
              drawBorder: this.showXBorder,
              drawOnChartArea: this.showXIntraLines,
              drawTicks: false,
              borderDash: this.dashedLines ? [2, 2] : undefined,
            },
            ticks: {
              padding: 12,
              display: this.showXLabels,
              color: this.ticksColor
            },
            title: {
              color: this.ticksColor
            }
          },
          y: {
            grid: {
              drawBorder: this.showYBorder,
              drawOnChartArea: this.showYIntraLines,
              drawTicks: false,
              borderDash: this.dashedLines ? [2, 2] : undefined,
            },
            ticks: {
              padding: 12,
              display: this.showYLabels,
              color: this.ticksColor
            },
            title: {
              color: this.ticksColor
            }
          },
        },
      }
      return mergeObjects(defaultConfig, this.chartOptions || {})
    },
    chartDescription() {
      return `${this.$t('Wcag.BarChart')} ${ this.description? ', ' + this.description : '' }`
    }
  },
  watch: {
    $props: {
      handler() {
        // force chart refresh
        this.$refs.chart.renderChart(this.chartData, this.config)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
