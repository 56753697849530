<template>
	<AnalyticsCard layout="vertical" :title="widgetTitle" :showLoader="isLoading">
		<template #chart>
			<div>
				<div v-if="map.length" id="clickRateByOrg" class="pb-4" role="grid">
					<div v-for="(org, index) in map" ref="heatmap" :key="index" class="heatmap-row mx-4" role="row"
						:class="[rowClicked == -1 ? '' : rowClicked == index ? 'heatmap-active-row' : 'heatmap-unactive-row']">

						<!-- Heatmap shows campaign click rate by org -->
						<tooltip>
							<div class="pl-0 heatmap-org col">
								<span :id="org.org_label">{{org.org_label}}</span>
							</div>
							<template #content>{{ org.org_label }}</template>
						</tooltip>

						<!-- For each org, display a cell with each campaign click rate -->
						<div v-for="(campaign, count) in org.campaigns" :key="count" class="relative px-0 heatmap-col col">
							<div :id="org.org_label + '_' + campaign.name" 
								class="h-full flex col px-0" 
								:style="`background-color: ${gradient.rgbAt((campaign.data.click_rate/100))}; height: 35px`" 
								role="gridcell"
								tabindex="0">
								<div class="h-full w-full grid">	
									<Tooltip mode="hover" placement="top">
										<span>{{ Number(campaign.data.click_rate).toFixed(1) }}%</span>
										<div class="tooltip-mask"></div>
										<template #content>
											<span><b>{{ org.org_label }}</b></span><br>
											<span>{{ campaign.name }}</span><br>
											<span>{{ $t('Analytics.Heatmap.ClickRate', { param: campaign.data.click_rate + '%' }) }}</span><br>
											<span>{{ $t('Analytics.Heatmap.Clicked', { param: campaign.data.clicked }) }}</span><br>
											<span>{{ $t('Analytics.Heatmap.Sent', { param: campaign.data.sent }) }}</span>
										</template>
									</Tooltip>
								</div>
							</div>
							<!-- Display campaign name after the last row -->
							<div v-if="index == map.length -1" class="col absolute w-full"> 
								<Tooltip mode="hover" placement="top">
									<span class="heatmap-campaign-name">{{ campaign.name }}</span>
									<div class="tooltip-mask"></div>
									<template #content>
										<span>{{ campaign.data.period }}</span>
									</template>
								</Tooltip>
							</div>
						</div>
					</div>
				</div>
				<div v-else >
					<Txt weight="medium">{{ $t('Dashboard.Charts.NoData') }}</Txt>
				</div>
			</div>
		</template>
		<template v-if="options.length" #menu-items>
			<MenuItem v-for="opt in options" :key="opt.key" @click="showData(opt)">{{opt.label || opt.key}}</MenuItem>
		</template>
    </AnalyticsCard>
</template> 

<script>
import phishingService from '@/services/phishing.service.js'
import tinygradient from "tinygradient";
import {NotAssignedLabel} from '@/common/constants';

export default {
	props: {
		options: Array,
		apiFilter: Object
	},
	data() {
		return {
			isLoading: false,
			data: {},
			orgs: [],
			campaigns: [],
			map: [],
			myself: "tag",
			gradient: [],
			max: 0,
			rowClicked: -1,
			widgetTitle: this.$t('Analytics.ClickRateByParam', { param: 'Org'}),
			counter: 0
		};
	},
    watch: {
        '$props.apiFilter': {
            handler() {
                this.loadData()
            },
            deep: true
        }
    },
	async mounted(){
		this.loadData()
	},
	methods: {
		loadData() {
			this.data = {}
			const team = this.options && this.options.length > 0 && this.options.find(o=>o.team)
        	this.showData(team || this.options[0])
		},
		async showData(tag){
			try {
				this.widgetTitle = this.$t('Analytics.ClickRateByParam', { param: tag?.label || tag?.key || '-'});
				if (!tag) return;

				this.counter++;
				this.isLoading = true;

				this.$forceUpdate();
				
				if (!this.data[tag.key]) {
					const resClickersByTag = await phishingService.getClickersByTagSummary(this.$currentCompany.company_id, tag.key, this.apiFilter)
					this.data[tag.key] = resClickersByTag.data || []
				}
				this.createHeatMap(this.data[tag.key], true);
			} catch (error) {
				this.$eventBus.$emit('show-alert', {title: this.$t('General.DataFetchError'), variant: 'danger'});
			} finally {
				if (this.counter > 0) this.counter--;
				if (!this.counter) this.isLoading = false;
			}
        },
		createHeatMap(data, reload) {
			// Add click rate numeric value to the dataset (always performed) and evaluate max click rate
			this.max = 0;
			data.forEach((row) => {
				row.click_rate = row.sent > 0 ? Number(100 * (row.clicked / row.sent)).toFixed(1) : 0;
				if(row.click_rate - this.max > 0) {
					this.max = row.click_rate;
				}
			});

			// Generate click rate gradient according to max click rate value found
			if(this.max == 0) {
				// If the max is 0, we define a custom green gradient
				this.gradient = tinygradient([ {color: '#b6e5bc'}, {color: '#b6e5bc'} ]);
			} else {
				this.gradient = tinygradient([
					{color: '#b6e5bc', pos: 0 },
					{color: '#fff454', pos: this.max/200 },
					{color: '#f7797d', pos: this.max/100 }
				]);
			}

			// When the view is first rendered or reloaded, get org and campaign list
			data.sort((a,b) => { return a.sequence - b.sequence });
			this.orgs = [...new Set(data.map(item => item[this.myself]))];
			this.campaigns = [...new Set(data.map(item => item.campaign))];
			this.campaign_meta = [];
			this.campaigns.forEach((c) => {
				let camp = data.filter(a => c === a.campaign)[0]
				let start = this.$dateTime.fromFormat(camp.planned_start, 'yyyy-MM-dd HH:mm:ss').toFormat('dd.MM.yyyy');
				let end = this.$dateTime.fromFormat(camp.planned_end, 'yyyy-MM-dd HH:mm:ss').toFormat('dd.MM.yyyy');
				this.campaign_meta[c] = { 
					'id': camp.campaign_id,
					'period': `${start} - ${end}`
				}
				
			});

			// Get a copy of current heatmap matrix 
			var tempMap = this.map;

			// Empty heatmap matrix
			this.map = [];

			// For each org, represented on rows
			this.orgs.forEach((org) => {
				var tempCampaigns = [];

				// For each campaign, represented on columns
				this.campaigns.forEach((campaign) => {

					// Find the correspondant element, if any. This is a single heatmap "cell"
					let cell = data.filter((el) => { return el[this.myself] == org && el.campaign == campaign; });
					if(cell.length > 0) {
						cell[0].period = this.campaign_meta[campaign].period;
					}

					if(reload) {
						// Heatmap is forced to reload
						if(cell.length > 0) {
							tempCampaigns.push({'name': campaign, 'data': cell[0]});
						} else {
							tempCampaigns.push({'name': campaign, 'data': { 'campaign_id': this.campaign_meta[campaign].id, 'click_rate': 0, 'sent': 0, 'clicked': 0, 'period': this.campaign_meta[campaign].period } });
						}
					} else {
						// The heatmap is already initialized, there are already (org * campaign) cell defined
						
						// Find in the heatmap copy the right row to update
						let o = tempMap.filter((el) => { return el[this.myself] == org });
						// Find in the row the right cell to update
						let c = o[0].campaigns.filter((el) => { return el.name == campaign; });
						
						if(cell.length > 0) { 
							// If data for that cell has been found, update the cell
							c[0].data = cell[0];
						} else {
							// Blank the cell since no data has been found
							c[0].data = { 'campaign_id': this.campaign_meta[campaign].id, 'click_rate': 0, 'sent': 0, 'clicked': 0, 'period': this.campaign_meta[campaign].period};
						}
						
						// Save the cell
						tempCampaigns.push(c[0]);
					}
				});

				let orgLabel = org == null ? this.$i18n.t('Analytics.NA') : org;
				let orgName = org == null ? this.myself + "_" + NotAssignedLabel : org;

				// Fill the map row by row
				this.map.push({ [this.myself]: org, 'org_filter_name': orgName, 'org_label': orgLabel, 'campaigns': tempCampaigns });
			});
		}
	}
}
</script>

<style lang="scss">
#clickRateByOrg {
	.heatmap-row {
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		text-align: center; 
	}
	.col {
		flex-basis: 0;
		-ms-flex-positive: 1;
		-webkit-box-flex: 1;
		flex-grow: 1;
	}
	.heatmap-org {
		line-height: 35px; 
		height: 35px; 
		width: 70px;
		white-space: nowrap; 
		overflow: hidden; 
		text-overflow: ellipsis;
	}
	.heatmap-col {
		margin-bottom: 1px; 
		margin-right: 1px;
	}
	.heatmap-campaign-name {
		white-space: nowrap; 
		font-size: 12px; 
		overflow: hidden; 
		text-overflow: ellipsis;
	}
	.heatmap-unactive-row {
		-webkit-filter: grayscale(50%);
		-moz-filter: grayscale(50%);
		-ms-filter: grayscale(50%);
		-o-filter: grayscale(50%);
		filter: grayscale(50%);
		filter: gray;
		.heatmap-org {
			font-weight: normal;
		}
	}
	.heatmap-active-row {
		.heatmap-org {
			font-weight: bold;
		}
	}
	.card-title {
		height: fit-content !important;
	}
}

.tooltip-mask {
    height: 100%; 	
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

// Small devices (landscape phones, 576px and up)
@media only screen and (min-width: 575px) { }

// Medium devices (tablets, 768px and up)
@media only screen and (min-width: 767px) {
	.heatmap-clickrate {
		font-size: 11px;
	}
}

// Large devices (desktops, 992px and up)
@media only screen and (min-width: 991px) {
	.heatmap-clickrate {
		font-size: 12px;
	}
}

// Extra large devices (large desktops, 1200px and up)
@media only screen and (min-width: 1199px) { 
	.heatmap-clickrate {
		font-size: 14px;
	}
}
</style>