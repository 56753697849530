import { apiClient } from '@/plugins/api'
import { Perm } from '../common/constants'

export default {
  getRoles(accountId, companyId) {
    return apiClient.get(`admin/company/${companyId}/account/${accountId}/roles`)
  },
  updateAccountRoles(companyId, accountId, payload){
    return apiClient.put(`admin/company/${companyId}/account/${accountId}/assign-company`, payload)
  },
  getSingleAccount(accountId, companyId) {
    return apiClient.get(`admin/company/${companyId}/account/${accountId}`)
  },
  createAccount(companyId, payload) {
    return apiClient.post(`admin/company/${companyId}/account`, payload)
  },
  updateAccount(accountId, companyId, payload) {
    return apiClient.put(`admin/company/${companyId}/account/${accountId}`, payload)
  },
  updateProfileAccount(accountId, companyId, payload) {
    return apiClient.put(`admin/company/${companyId}/account/${accountId}/profile`, payload)
  },
  deleteAccount(companyId, accountId) {
    return apiClient.delete(`admin/company/${companyId}/account/${accountId}`)
  },
  importAccounts(companyId, payload) {
    let opt = {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    }
    return apiClient.post(`admin/company/${companyId}/account/bulk`, payload, opt)
  },
  deleteAccountByUsername(companyId, username) {
    return apiClient.delete(`admin/company/${companyId}/account-username/${username}`)
  },
  getApprovers(companyId) {
    let opt = {
      params: { permissions: Perm.campaign.approve }
    }
    return apiClient.get(`admin/company/${companyId}/accounts-with-permission`, opt); 
  },
  exportAccounts(companyId) {
    let opt = { responseType: 'blob' }
    return apiClient.get(`admin/company/${companyId}/export-account`, opt)

  },
  getAccounts(companyId, searchText, status, page, itemsPerPage, sortKey, sortDir) {
    const opt = {
      params: {
        search: searchText,
        status: status,
        _page: page,
        _limit: itemsPerPage,
        sortKey: sortKey,
        sortDir: sortDir
      }
    }
    return apiClient.get(`admin/company/${companyId}/account`, opt)
  },
  getAllAccounts(searchText, companyId, tags, page, itemsPerPage, sortKey, sortDir) {
    const opt = {
      params: {
        search: searchText,
        companyId: companyId,
        tags: tags,
        _page: page,
        _limit: itemsPerPage,
        sortKey: sortKey,
        sortDir: sortDir
      }
    }
    return apiClient.get(`admin/account`, opt)
  },
  getTeamAccounts(companyId, teamName) {
    return apiClient.get(`admin/company/${companyId}/account?team=${teamName}`)
  },
  changePassword(uuid, companyId, payload) {
    return apiClient.put(`admin/company/${companyId}/account/${uuid}/change-password`, payload)
  },
  sendPassword(accountUUID, companyId) {
    return apiClient.post(`admin/company/${companyId}/account/${accountUUID}/send-password`)
  },
  getLastUnreadNotification(accountUUID, companyId) {
    return apiClient.get(`notification/company/${companyId}/account/${accountUUID}/notification/last`)
  },
  getNotifications(accountUUID, companyId, lang_code) {
    const opts = {
      params: {
        lang_code
      }
    }
    return apiClient.get(`notification/company/${companyId}/account/${accountUUID}/notification`, opts)
  },
  updateNotification(accountUUID, companyId, notificationId, payload) {
    return apiClient.put(`notification/company/${companyId}/account/${accountUUID}/notification/${notificationId}`, payload)
  },
  logOut(companyName) {
    let kc_url = '?rd=' + process.env.VUE_APP_KEYCLOAK_URL
    return apiClient.get(`oauth2/sign_out${kc_url}/auth/realms/${companyName}/protocol/openid-connect/logout`)
  },
  sendWelcomeMail(accountUUID, companyId) {
    return apiClient.post(`notification/company/${companyId}/account/${accountUUID}/send-first-access`)
  },
}
