<template>
  <Modal :open.sync="modalState" :trigger="trigger" :title="title" footer-alignment="right" :with-expand-button="withExpandButton" :with-close-button="withCloseButton" :full-height="fullHeight" :small="small" :footer-visible="footerVisible">
    <div class="w-full h-auto px-2 py-2 modalform" >
      <div class="grid gap-x-10 gap-y-6 grid-cols-1" :class="{'sm:grid-cols-2':multipleColumns}">
        <FormGroup v-for="(item, index) in visibleFields" :key="index" :label="item.noTranslate? item.label : $t(item.label)" :required="item.required" :html-for="item.id" no-margin>

          <Input v-if="item.type == 'text' || item.type == 'number'" :id="item.id" v-model="dataItem[item.id]" :type="item.type" :readonly="item.readonly" :disabled="item.disabled" :placeholder="$t(item.placeholder)"
                 :errored="item.errored" :error-message="item.error" :min="item.min" :max="item.max" :step="item.step" @input="validate($event, item);propagateEvent($event, item)" />
          <Select v-if="item.type == 'select'" :id="item.id" v-model="dataItem[item.id]" :placeholder="$t(item.placeholder)" :multiple="item.multiple" :readonly="item.readonly" :disabled="item.disabled" 
                  :value="dataItem[item.id]" :options="item.options" :erasable="item.erasable" @input="validate($event, item);propagateEvent($event, item)"/>
          <Toggle v-if="item.type == 'toggle'" :id="item.id" v-model="dataItem[item.id]" :checked-value="item.checkedValue" :unchecked-value="item.uncheckedValue"
                  :value="dataItem[item.id]" @input="propagateEvent($event, item)"/>
          <DatePicker v-if="item.type == 'date'" :id="item.id" v-model="dataItem[item.id]" :disabled="item.disabled"
                      :errored="item.errored" :error-message="item.error" @input="validate($event, item)"/>
          <InputTags v-if="item.type == 'tag-input'" :id="item.id" v-model="dataItem[item.id]" :options="item.options" :allow-others="item.allowOthers" :max="item.max"
          :placeholder="$t(item.placeholder)" @input="manageTag(item, $event)" @remove="manageTag(item, $event)" />
          <Textarea
              v-if="item.type == 'textarea'" :id="item.id" v-model="dataItem[item.id]" :rows="item.rows" :cols="item.cols" :readonly="item.readonly" :disabled="item.disabled"
              :placeholder="$t(item.placeholder)" :errored="item.errored" :error-message="item.error" :no-resize="item.noResize"
              @input="validate($event, item)"
           />
          <img v-if="item.type == 'image'" :src="dataItem[item.id]" />

          <!-- File input field -->
          <Input v-if="item.type == 'file'" :id="item.id" v-model="fileNames[item.id]" :required="item.required" :readonly="item.readonly" :disabled="item.disabled" :placeholder="$t(item.placeholder)"
            :accept="item.acceptFileType" :errored="item.errored" :error-message="item.error" @click="uploadFile(item)">
            <template #suffix>
              <Button :ref="'fileUploadBtn'+item.id" @click="uploadFile(item)">
                {{ item.noTranslate? item.label : $t(item.label) }}
              </Button>
            </template>
          </Input>
          <FileUploadModal v-if="item.type == 'file'" :ref="'fileInput'+item.id" :trigger="fileUploadRef" :file-label="'fileInput'+item.id" :max-size="item.maxSize" :allow-multiple="item.multiple" :accepted-formats="item.acceptedFormats" @submit="fileChanged($event, item)" />

        </FormGroup>
      </div>
    </div>

    <template #footer>
      <div class='flex flex-grow items-center justify-between w-full'>
        <div class='w-2/3 text-white' >
          <ProgressBar v-if='uploadProgress !== -1' :value='uploadProgress' mode='determinate' :show-value='true' color='#1F1584'></ProgressBar>
        </div>
        <div class='flex'>
          <Button variant="outline" class='mr-3' @click="fileNames={};modalState=false">{{ $t('General.Cancel') }}</Button>
          <Button :disabled="hasError" @click="submitForm()">{{ $t('General.Save') }}</Button>
        </div>
      </div>
    </template>

  </Modal>
</template>

<script>
import Vue from 'vue';

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    title: {
      type: String,
      default: ''
    },
    multipleColumns: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false
    },
    fullHeight: {
      type: Boolean,
      default: true
    },
    footerVisible: {
      type: Boolean,
      default: true
    },
    withCloseButton: {
      type: Boolean,
      default: true
    },
    withExpandButton: {
      type: Boolean,
      default: false
    },
    uploadProgress: {
      type: Number,
      default: -1
    },
    trigger: {
      default: null,
    },
  },
  data() {
    return {
      dataItem: {},
      fileNames: {},
      modalState: false,
      fileUploadRef: null,
    };
  },
  computed: {
    hasError() {
      let errorList = this.fileNames && this.data && this.data.fields && this.data.fields.filter((el) => {
        const value = this.dataItem[el.id]
        return el.errored || (el.required && (!value || Array.isArray(value) && !value.length))
      });
      return errorList && errorList.length > 0;
    },
    visibleFields() {
      return this.data.fields.filter(f => (!('display' in f) || f.display))
    }
  },
  watch:{
    dataItem(){
      this.$props.data.item = this.dataItem
    }
  },
  methods: {
    show(value) {
      this.modalState = value;
      this.fileNames = {}
      this.dataItem = Object.assign({}, this.data.item);
    },
    validate(value, field) {
      Vue.set(field, 'errored', false)
      Vue.set(field, 'error', '')
      if (typeof field.validator == 'function' && value != '') {
        this.formValid = field.validator(value, this.dataItem[field.id]);
        if (!this.formValid) {
          Vue.set(field, 'errored', true)
          Vue.set(field, 'error', this.$t(field.errorMessage))
        }
      } else if (field.type == 'number' && 'min' in field && 'max' in field && value != '') {
        if (Number(value) < field.min || Number(value) > field.max) {
          Vue.set(field, 'errored', true)
          Vue.set(field, 'error', this.$t(field.errorMessage))
        }
      }
    },
    propagateEvent(selected, item) {
      if(item.change && typeof item.change == 'function') {
        item.change(selected);
      }
    },
    uploadFile(item){
      this.fileUploadRef = this.$refs['fileUploadBtn'+item.id]
      this.$refs['fileInput'+item.id][0].showUploadModal(true)
    },
    fileChanged(fileRes, item) {
      this.dataItem[item.id] = (item.multiple)? fileRes.fileList : fileRes.fileList[0]
      let fileNames = fileRes.fileList.map(file=>file.name).join(',')
      Vue.set(this.fileNames, item.id, fileNames)
      this.validate(fileNames, item)
    },
    manageTag(item, tags) {
      this.dataItem[item.id] = tags;
      if (this.dataItem[item.id].length == 0) {
        item['clean'](this.dataItem, item.id);
      } else {
        item['manage'](this.dataItem, item.id);
      }
    },
    async submitForm() {
      this.fileNames = {};
      this.$emit('update', this.dataItem);
    },
  }
};
</script>

<style>
.modalform .form-group label {
  display: inline-block !important;
}
</style>
