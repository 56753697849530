<template>
	<div>
	 <div class="analytics-table">
		<Loader :show="isLoading"/>
			<Txt weight="bold" class="analytics-card-title mb-3" no-margin>{{$t('Dashboard.Charts.ClickRateByParam', { param : 'target'})}}</Txt>
		
			<!-- Search and filter -->
			<div v-if="targetsShown.length" class="flex flex-row gap-4 mb-8 justify-end items-center">
				<FormGroup html-for="targetFilterOptions" :label="$t('General.Status')" sr-only>
					<Select
						id="targetFilterOptions"
						v-model="targetStatusFilter"
						:options="targetStatusOption"
						class="justify-self-end"
						@input="showTargets"
					></Select>
				</FormGroup>
				<label for="searchTarget" class="sr-only">{{ $t('General.Search') }}</label>
				<Input
					id="searchTarget"
					v-model="context.searchText"
					:placeholder="$t('General.TypeToSearch')"
					unstyled-suffix
					@keyup.native.enter="refresh"
				>
				<template #suffix>
					<Icon name="search" class="text-slate-500 cursor-pointer" @click.native="refresh"/>
				</template>
				</Input>
			</div>

			<Table v-if="targetsShown.length" :name="$t('Navbar.Targets')" alternate-color="white">
				<template #head>
					<TableCell v-for="(prop, i) in props" :key="i" head >{{prop}}</TableCell>
				</template>
				<TableRow v-for="(target, i) in targetsShown" :key="i" :record="target">
					<TableCell>
						<Txt weight="bold">{{ target.name }}</Txt>
					</TableCell>
					<TableCell>{{ target.team }}</TableCell>
					<TableCell>{{ target.sent }}</TableCell>
					<TableCell>{{ target.clicked }}</TableCell>
					<TableCell>{{ target.click_rate}}%</TableCell>
					<TableCell>{{ $t(target.clicked_last? 'General.Yes' : 'General.No') }}</TableCell>
					<TableCell>{{ target.risk_group | riskGroup }}</TableCell>
					<TableCell>{{ target.subject  }}</TableCell>
				</TableRow>
			</Table>

			<div v-else class="my-8">
				<Txt weight="medium">{{ $t('General.TableDataPending') }}</Txt>
			</div>
		</div>
		<Pagination v-if="dataLength" :page="page" :items-per-page="itemsPerPage" :total-items="dataLength" :context="$t('General.TargetsPerPage')" class="mt-2" @page-change="pageChange"/>
	</div>
</template>

<script>
import phishingService from '@/services/phishing.service.js'

export default {
	props: {
		team: Object,
		apiFilter: Object,
		companyData: Object
	},
	data() {
		return {
			isLoading: false,
			data: [],
			dataLength: 0,
			page: 1,
			targetsShown : [],
			itemsPerPage : 10,
			title: this.$t('Dashboard.Charts.ClickRateByParam', { param : 'target'}),
			props:[ 
				this.$t('Dashboard.Charts.Target'), this.team?.label || this.team?.key, 
				this.$t('Dashboard.Charts.Sent'), this.$t('Dashboard.Charts.Clicked'), this.$t('Dashboard.Charts.ClickRate'),
				this.$t('Dashboard.Charts.ClickedLastCampaign'),this.$t('Dashboard.Charts.CyberStrength'), this.$t('Dashboard.Charts.LastTemplateObject'),
			],
			cyberDefenders: 4,
			rareClickers: 3,
			targetStatusFilter: null,
			targetStatusOption: [],
			myself: 'clickers',
			context: {
				searchText: "",
				sortBy: "click_rate",
				sortDesc: true
			},
			counter: 0
		}
	},
	watch: {
		apiFilter: function () {
			this.refresh();
		}
	},
	mounted() {
		this.context = { searchText: "", sortBy: "click_rate", sortDesc: true };
		this.refresh();
	},
	methods:{
		refresh() {
			this.loadData().then(() => {
				this.showTargets();
			});
		},
		async loadData() {
			try {
				this.counter++;
				this.isLoading = true;

				this.targetStatusOption = [
					{ value: null, label: this.$t('Targets.SuspendedAndActiveOption')},
					{ value: '1', label: this.$t('Targets.ActiveOption')}
				];

				// load data
				const res = await phishingService.getCompanySummary(this.$currentCompany.company_id, { 
					code: 'clickers', 
					context : this.context,
					team: this.team.key,
					...this.apiFilter });

				this.page = 1;

				this.data = res.data;
			} catch (error) {
				this.$eventBus.$emit('show-alert', {title: this.$t('General.DataFetchError'), variant: 'danger'});
			} finally {
				if (this.counter > 0) this.counter--;
				
				if (!this.counter) this.isLoading = false;
			}
		},
		pageChange(page, itemsPerPage) {
			this.page = page;
			this.itemsPerPage = itemsPerPage;
			this.showTargets();
		},
		showTargets() {
			const start = (this.itemsPerPage*this.page)-this.itemsPerPage;
			const end = this.itemsPerPage*this.page;
			const data = this.targetStatusFilter? this.data.filter(((t) => { return t.deleted != 1 })) : this.data;
			this.dataLength = data.length;
			this.targetsShown = [...data].slice(start, end);

			// Shows cyber defenders only if reporting_enabled is true, else shows defenders as rare
			if(!this.companyData?.reporting_enabled) {
				this.targetsShown.forEach((t) => { 
					if(t.risk_group == this.cyberDefenders) t.risk_group = this.rareClickers; 
				});
			}
		}
	}
};
</script>
<style scoped lang="scss">
	.analytics-table {
		padding: 20px;
		background-color: white;
		border: solid 1px rgb(229, 231, 235);
		max-height: 650px ;
		overflow: auto;
		position: relative;

		.white-overlay {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
</style>