<template>
    <div>
	<div class="analytics-table">
        <Loader :show="isLoading"/>

            <TemplateModal
                ref="cgTemplateModal"
                :company-data="companyData"
                :campaign-id="campaignId"
                :is-read-only="true"
                :trigger="selTemplateRef"
            ></TemplateModal>
            <Txt weight="bold" class="analytics-card-title mb-3" no-margin>{{$t('Dashboard.Charts.ClickRateByParam', {param : 'template'})}}</Txt>
            <Table v-if="itemsShown.length" :name="$t('Navbar.Template')" alternate-color="white">
                <template #head> 
                    <TableCell v-for="(prop, i) in props" :key="i" head>{{prop}}</TableCell>
                </template>
                <TableRow v-for="(template, i) in itemsShown" :key="i" :record="template">
                    <TableCell>
                        <Txt weight="bold">{{ template.name }}</Txt>
                    </TableCell>
                    <TableCell>{{  $t(template.used_last? 'General.Yes' : 'General.No') }}</TableCell>
                    <TableCell>{{ template.type | templateType  }}</TableCell>
                    <TableCell>{{ template.sent }}</TableCell>
                    <TableCell>{{ template.clicked }}</TableCell>
                    <TableCell>{{ ((template.clicked / template.sent) * 100).toFixed(2) }}%</TableCell>
                    <TableCell>
                        <Button :ref="'template_' + template.template_id" small variant="ghost" :aria-label="$t('General.Show')" class="text-primary-300 hover:text-violet-700"
                            @click.native="preview(template)" @keydown.native.enter="preview(template)">
                            <Icon :size="18" name="eye"/>
                        </Button>
                    </TableCell>
                </TableRow>
            </Table>
            <div v-else class="my-8">
				<Txt weight="medium">{{ $t('General.TableDataPending') }}</Txt>
			</div>
        </div>
        <Pagination v-if="data.length" :page="page" :items-per-page="itemsPerPage" :total-items="data.length" :context="$t('General.TemplatesPerPage')" class="mt-2" @page-change="pageChange"  />
    </div>
</template> 

<script>
import campaignService from '@/services/campaign.service.js'
import phishingService from '@/services/phishing.service.js'

export default {
    props: {
        apiFilter: Object
    },
	data() {
		return {
            isLoading: false,
            data: [],
            page: 1,
            itemsShown : [],
            itemsPerPage : 10,
			title: this.$t('Dashboard.Charts.ClickRateByParam', {param : 'template'}),
            props:[ 
                this.$t('Dashboard.Charts.TemplateName'), this.$t('Dashboard.Charts.Active'), 
                this.$t('Dashboard.Charts.Type'), this.$t('Dashboard.Charts.Sent'),
                this.$t('Dashboard.Charts.Clicked'), this.$t('Dashboard.Charts.ClickRate'),
                this.$t('Template.Preview')
            ],
            companyData: { company: { company_id: this.$currentCompany.company_id } },
            campaignId: null,
            config: null,
            counter: 0,
            selTemplateRef: null
		}
	},
    watch: {
        '$props.apiFilter': {
            handler() {
                this.loadData()
            },
            deep: true
        }
    },
    async created() {
        this.config = (await phishingService.getConfig()).data;
    },
    mounted(){
        this.loadData()
    },
	methods:{
        async loadData() {
            try {
                this.counter++;
                this.isLoading = true;
                this.companyData = { company: { company_id: this.$currentCompany.company_id } };
                const res = await phishingService.getCompanySummary(this.$currentCompany.company_id, { code: 'template', ...this.apiFilter });    
                this.data = res.data;

                this.showItems();
            } catch (error) {
                this.$eventBus.$emit('show-alert', {title: this.$t('General.DataFetchError'), variant: 'danger'});
            } finally {
                if (this.counter > 0) this.counter--;

                if (!this.counter) this.isLoading = false;
            }
        },
        pageChange(page, itemsPerPage) {
            this.page = page
            this.itemsPerPage = itemsPerPage
            this.showItems()
        },
        showItems(){
            const start = (this.itemsPerPage*this.page)-this.itemsPerPage
            const end = this.itemsPerPage*this.page
            this.itemsShown = [...this.data].slice(start, end)
        },
        async preview(item) {
            try {
                this.$eventBus.$emit('loading', true);
                let result = await campaignService.getCampaignTemplateById(this.$currentCompany.company_id, item.campaign_id, item.template_id);
                item.body = result.data.length ? result.data : [{ body: "", hints: 0 }];
                item.languages = result.data.length;
                item.landing_page_url = this.config.defaults.gp_landing_url;
                this.template = item;
                this.campaignId = item.campaign_id;
                this.selTemplateRef = this.$refs['template_' + item.template_id]; 
                this.$refs.cgTemplateModal.showPreviewModal(this.template);
            } catch (error) {
                this.$eventBus.$emit('show-alert', { title: this.$t('Template.TemplateNotFoundError'), variant: 'danger' });
            } finally {
                this.$eventBus.$emit('loading', false);
            }
		},
    }
};
</script>


<style scoped lang="scss">
    .analytics-table {
        padding: 20px;
        background-color: white;
        /* border: solid 1px #050d2c; */
        border: solid 1px rgb(229, 231, 235);
        max-height: 650px;
        min-height: 150px;
        overflow: auto;
        position: relative;

        .white-overlay {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
</style>