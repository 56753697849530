import { apiClient } from '@/plugins/api'

export default {
  getModuleStatus(companyId, cgaTypeId) {
    const params = {}
    params.cga_type_id = cgaTypeId
    return apiClient.get(`report/company/${companyId}/get_modules_status`, params)
  },
  // TODO Per convenzione quando possibile spostare la generazione delle params all'interno della funzione
  //Ps meglio params che options, in quanto quello e' il parametro aggiuntivo di apiClient e potrebbe creare confusione
  getModulesTimes(companyId, params) {
    return apiClient.get(`report/company/${companyId}/get_modules_times`, params)
  },
  getModulesScores(companyId, params) {
    return apiClient.get(`report/company/${companyId}/get_modules_scores`, params)
  },
  getUserStatus(companyId, params) {
    return apiClient.get(`report/company/${companyId}/get_users_status`, params)
  },
  getEmailsByStatus(companyId, options) {
    return apiClient.get(`report/company/${companyId}/get_emails_by_status`, options)
  },
  getCompanyStats(companyId, params) {
    return apiClient.get(`report/statistics/company/${companyId}/stats`, params)
  },
  getCompanyUserAnalytics(companyId) {
    return apiClient.get(`admin/company/${companyId}/analytics`)
  },
  getModuleStatusByTeam(companyId, params) {
    return apiClient.get(`report/company/${companyId}/get_modules_status`, params)
  },
  getUserCyberScore(companyId, userId) {
    return apiClient.post(`report/company/${companyId}/account/${userId}/get_cyber_score`)
  },
  getCompanyCyberScore(companyId, tags) {
    return apiClient.post(`report/company/${companyId}/get_cyber_score`, { ... tags && {tags} })
  },
  getTeamCyberScore(companyId, team = null) {
    let params = {}
    if (team) params.team = team
    return apiClient.post(`report/company/${companyId}/get_cyber_score`, params)
  },
  getCompanyRiskScore(companyId, params) {
    return apiClient.post(`report/statistics/company/${companyId}/get_risk_score`, params)
  },
  getPhishingStats(companyId, params) {
    return apiClient.get(`report/statistics/company/${companyId}/get_phishing_stats`, params)
  },
  getScoreHistory(companyId, userId = null, teamName = null) {
    let params = {}
    if (userId) params.accountId = userId
    if (teamName) params.team = teamName

    return apiClient.post(`report/stats_history/${companyId}/get_score_history`, params)
  },
  reportRank(companyId, team = false) {
    let opt = { responseType: 'blob', params: {} }
    if (team) opt.params.team = team
    opt.params.companyId = companyId
    opt.params.filename = !team ? 'reportRank' : 'reportTeamRank'

    return apiClient.get(`report/rank-report`, opt)
  },
  reportModuleRank(companyId) {
    return apiClient.get(`report/module-status-report?companyId=${companyId}`)
  },
  getUserRankingReport(companyId, team = null, lang = null, filename = null, file_type = null, filterTags = null) {
    let opt = {
      responseType: 'blob',
      params: {
        companyId: companyId,
        team: team,
        lang: lang,
        filename: filename,
        file_type: file_type,
        tags: filterTags
      }
    }
    return apiClient.get(`report/user-ranking`, opt)
  },
  getTeamRankingReport(companyId, lang = null, filename = null, file_type = null, filterTags = null) {
    let opt = {
      responseType: 'blob',
      params: {
        companyId: companyId,
        lang: lang,
        filename: filename,
        file_type: file_type,
        tags: filterTags
      }
    }
    return apiClient.get(`report/team-ranking`, opt)
  },
  getCompletedUserModules(companyId, team = null, service_id = null, lang = null, filename = null,
                          file_type = null, filterTags = null, start_date = null, end_date = null) {
    let opt = {
      responseType: 'blob' ,
      params: {
        companyId: companyId,
        team: team,
        service_id: service_id,
        lang: lang,
        filename: filename,
        file_type: file_type,
        tags: filterTags,
        start_date: start_date,
        end_date: end_date
      }
    }
    return apiClient.get(`report/completed-user-module`, opt)
  },
  reportGlobalParticipation(companyId, team = null, lang = null, filename = null, file_type = null, filterTags = null) {
    let opt = {
      responseType: 'blob' ,
      params: {
        companyId: companyId,
        team: team,
        lang: lang,
        filename: filename,
        file_type: file_type,
        tags: filterTags
      }
    }
    return apiClient.get(`report/global-participation`, opt)
  },
  reportAwarenessParticipation(companyId, team = null, lang = null, filename = null, file_type = null, filterTags = null) {
    let opt = {
      responseType: 'blob' ,
      params: {
        companyId: companyId,
        team: team,
        lang: lang,
        filename: filename,
        file_type: file_type,
        tags: filterTags
      }
    }
    return apiClient.get(`report/awareness-participation`, opt)
  },
  reportChannelParticipation(companyId, team = null, lang = null, filename = null, file_type = null, filterTags = null) {
    let opt = {
      responseType: 'blob' ,
      params: {
        companyId: companyId,
        team: team,
        lang: lang,
        filename: filename,
        file_type: file_type,
        tags: filterTags
      }
    }
    return apiClient.get(`report/channel-participation`, opt)
  },
  getServiceParticipation(companyId, payload) {
    return apiClient.post(`report/company/${companyId}/get_service_participation`, payload)
  },
  getUserModuleStatus(companyId, lang = null, file_type = null, service_id = null) {
    const params = {}
    if (lang) params.lang = lang
    if (file_type) params.file_type = file_type
    if (service_id) params.service_id = service_id
    let opt = { responseType: 'blob' }
    return apiClient.post(`report/company/${companyId}/get_user_module_status`, params, opt)
  },
  exportCompanyList(lang, file_name) {
    const params = {}
    if (lang) params.lang = lang
    if (file_name) params.file_type = file_name
    params.responseType = 'blob'
    return apiClient.get(`report/company-report`, params)
  },
  getEmailReportStats(accountId) {
    return apiClient.get(`report/cgp_target_timeline/email/account_uuid/${accountId}`)
  },
  getPhishingEmailReportStats(accountId) {
    return apiClient.get(`report/cgp_target_timeline/account_uuid/${accountId}`)
  },
  getRiskScore(accountId) {
    return apiClient.get(`report/statistics/get_risk_score/${accountId}`)
  },
  getUserProgress(accountId) {
    return apiClient.get(`report/statistics/user_progress/${accountId}`)
  },
  getUsersStats(searchText, companyId, page, itemsPerPage, sortKey, sortDir, team, tagFilter = null) {
    const opt = {
      params: {
        search: searchText,
        _page: page,
        _limit: itemsPerPage,
        sortKey: sortKey,
        sortDir: sortDir,
        team: team,
        tags: tagFilter
      }
    }
    return apiClient.get(`report/company/${companyId}/users-stats`, opt)
  },

  getOnlineUsers(companyId) {
    return apiClient.get(`report/company/${companyId}/online-users`)
  },

  aggregateDataStatsHistory(companyId,limitDate = null)
  {
    const payload = {
      companyId: companyId
    }

    if(limitDate) payload.limitDate = limitDate

    return apiClient.post(`report/stats_history/aggregate-data`, payload)
  },
  exportUserStatsList(companyId, lang, file_type, team, tags)
  {
    const opt = {
      responseType: 'blob' ,
      params: {
        lang: lang,
        file_type: file_type,
        ... team && {team},
        ... tags && {tags},
      }
    }
    return apiClient.get(`report/company/${companyId}/users-stats-report`, opt)
  },
  getLevelProgressSummary(companyId, levelId, params) {
    return apiClient.get(`report/company/${companyId}/level/${levelId}/summary`, { params })
  },
  exportLevelProgress(companyId, levelId) {
    return apiClient.get(`report/company/${companyId}/level/${levelId}/export`)
  },
  getLevelAccount(companyId, levelId) {
    return apiClient.get(`report/company/${companyId}/level/${levelId}/account`)
  }
}
