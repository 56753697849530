import { apiClient } from '@/plugins/api'
import { VideoMode } from '@/common/constants'

export default {
  getType(accountId) {
    return apiClient.get(`awareness/account/${accountId}/modules/cga_type`)
  },
  getAvailability(accountId) {
    return apiClient.get(`awareness/account/${accountId}/modules/availability`)
  },
  getModules(accountId, langCode, typesArr, page, itemsPerPage, sortKey, sortDir, availability, completed = false) {
    const opt = {
      params: {
        cga_type_id: typesArr,
        availability: availability,
        _page: page,
        _limit: itemsPerPage,
        sortKey: sortKey,
        sortDir: sortDir,
        lang: langCode
      }
    }
    if(completed) opt.params.completed = completed
    return apiClient.get(`awareness/account/${accountId}/modules/`, opt)
  },
  getCompanyModules(companyId, langCode, typesArr, page, itemsPerPage, sortKey, sortDir, availability) {
    const opt = {
      params: {
        cga_type_id: typesArr,
        availability: availability,
        page: page,
        _limit: itemsPerPage,
        sortKey: sortKey,
        sortDir: sortDir,
        lang: langCode,
        paginate: true
      }
    }
    return apiClient.get(`awareness/cga_modules/company/${companyId}/`, opt)
  },
  getUserActivitiesModules(companyId, accountId, langCode) {
    const opt = {
      params: {
        lang: langCode,
        companyId: companyId
      }
    }
    return apiClient.get(`awareness/account/${accountId}/get_user_activities/`, opt)
  },
  getUserAdditionalContentsModules(accountId, langCode) {
    const opt = {
      params: {
        lang: langCode
      }
    }
    return apiClient.get(`awareness/account/${accountId}/get_additional_contents/`, opt)
  },
  getModulesPercentProgress(accountId) {
    return apiClient.get(`awareness/account/${accountId}/completed_modules/`)
  },
  getModuleInformation(accountId, moduleId) {
    const opt = {
      params: {
        accountId: accountId,
        cga_module_uuid: moduleId
      }
    }
    return apiClient.get(`awareness/cga_registrations`, opt)
  },
  getLevelInformation(company_uuid,account_uuid = null,cga_level_uuid)
  {
    const opt = {
      params: {
        companyId: company_uuid,
        level_uuid: cga_level_uuid
      }
    }
    if(account_uuid) opt.params.account_uuid = account_uuid
    return apiClient.get(`awareness/cga_registrations`, opt)
  },
  getRegistrations(param) {
    const opt = {
      params: param
    }
    return apiClient.get(`awareness/cga_registrations`, opt)
  },
  resetRegistration(registrationUuid, tenant, companyId = null) {
    const params = {}
    const payload = {
       tenant: tenant
    }
    if(companyId) params.companyId = companyId
    return apiClient.put(`awareness/cga_registrations/${registrationUuid}/reset`, payload, { params: params || {} })
  },
  deleteRegistration(registrationUuid, tenant, companyId = null) {
   const opt = {
      params: { tenant: tenant }
    }
    if(companyId) opt.params.companyId = companyId
    return apiClient.delete(`awareness/cga_registrations/${registrationUuid}`, opt)
  },
  startModule(accountId, moduleId, tenant, lang, videoMode = VideoMode['FREE'], firstName = null, lastName = null, country = null) {
    let payload = {
      accountId: accountId,
      cga_module_uuid: moduleId,
      tenant: tenant,
      lang: lang,
      videoMode: videoMode,
      firstName: firstName,
      lastName: lastName,
      country: country
    }

    return apiClient.post(`awareness/find_or_create_registration`, payload)
  },
  startModulePreview(module_uuid,tenant,lang, companyId = null)
  {
    const opt = {
      params: {
        lang: lang,
        tenant: tenant
      }
    }
    if(companyId) opt.params.companyId = companyId
    return apiClient.get(`awareness/cga_modules/${module_uuid}/preview`,opt)
  },
  startAsset(companyId, accountId, moduleId, tenant, lang, firstName = null, lastName = null) {
    let payload = {
      accountId: accountId,
      cga_module_uuid: moduleId,
      tenant: tenant,
      lang: lang,
      firstName: firstName,
      lastName: lastName
    }

    return apiClient.post(`awareness/company/${companyId}/find_or_create_content_registration`, payload)
  },
  checkModuleLang(accountId, moduleId, lang) {
    let opt = {
      params: {
        accountId: accountId,
        cga_module_uuid: moduleId,
        lang: lang
      }
    }
    return apiClient.get(`awareness/check_registration_lang`, opt)
  },
  getAllModules(level_uuid, langCode, paginate, page, itemsPerPage, scope= 0, companyId = null, cga_type_id = null, search= null, sortKey= null, sortDir=null) {
    const opt = {
      params: {
        lang: langCode,
        paginate: paginate,
        page: page,
        _limit: itemsPerPage
      }
    }
    opt.params.scope = scope
    if (scope !== 1) opt.params.companyId = companyId
    if (level_uuid) opt.params.level_uuid = level_uuid
    if (cga_type_id) opt.params.cga_type_id = cga_type_id
    if (sortKey) opt.params.sortKey = sortKey
    if (sortDir) opt.params.sortDir = sortDir
    if (search) opt.params.search = search
    return apiClient.get(`awareness/cga_modules/`, opt)
  },
  getModule(module_uuid) {
    return apiClient.get(`awareness/cga_modules/${module_uuid}`)
  },
  getModuleTranslations(module_uuid, companyId = null) {
    const opt = { params : {} }
    if(companyId) opt.params.companyId = companyId
    return apiClient.get(`awareness/cga_modules/${module_uuid}/translation-keys`, opt)
  },
  createModule(payload, lang, companyId = null, callbackProgress = null) {
    const opt = {
      params: { lang: lang },
      headers: { 'Content-Type': 'multipart/form-data;' },
      onUploadProgress: callbackProgress
    }
    if(companyId) opt.params.companyId = companyId
    return apiClient.post(`awareness/cga_modules/with_assets`, payload, opt)
  },
  updateModule(payload, lang, uuid, companyId = null) {
    const opt = {
      params: { language: lang }
    }
    if(companyId) opt.params.companyId = companyId
    return apiClient.put(`awareness/cga_modules/${uuid}`, payload, opt)
  },
  updateModuleVersion(payload, uuid, companyId = null, callbackProgress = null) {
    const opt = {
      headers: { 'Content-Type': 'multipart/form-data;' },
      params : {},
      onUploadProgress: callbackProgress
    }
    if(companyId) opt.params.companyId = companyId
    return apiClient.post(`awareness/cga_modules/${uuid}/version`, payload, opt)
  },
  updateModuleVersionImage(payload, uuid, companyId = null)
  {
    const opt = {
      headers: { 'Content-Type': 'multipart/form-data;' },
      params : {}
    }
    if(companyId) opt.params.companyId = companyId
    return apiClient.post(`awareness/cga_modules/${uuid}/version-image`, payload, opt)
  },
  deleteModule(uuid, keepdata, companyId = null) {
    const opt = {
      params: {},
      data: { keepdata: keepdata }
    }
    if(companyId) opt.params.companyId = companyId
    return apiClient.delete(`awareness/cga_modules/${uuid}`, opt)
  },
  getLevels(company_uuid, lang, scope, companyId, context = null, serviceId = null, search = null, sortKey = null, sortDir = null) {
    const opt = {
      params: {
        companyId: company_uuid,
        lang: lang,
        scope: scope,
        service_id: serviceId
      }
    }
    if(context){
      opt.params._limit = context.perPage
      opt.params.page = context.currPage
      opt.params.paginate = true
    }

    if (scope != 1) opt.params.companyId = companyId
    if (search) opt.params.search = search
    if (sortDir) opt.params.sortDir = sortDir
    if (sortKey) opt.params.sortKey = sortKey

    return apiClient.get(`awareness/cga_levels/`, opt)
  },
  getLevel(lang, uuid, companyId = null) {
    const opt = {
      params: {
        lang: lang
      }
    }
    if(companyId) opt.params.companyId = companyId
    return apiClient.get(`awareness/cga_levels/${uuid}`, opt)
  },
  getLevelTranslation(uuid,companyId = null) {
    const opt = { params: {} }
    if(companyId) opt.params.companyId = companyId
    return apiClient.get(`awareness/cga_levels/${uuid}/translation-keys`, opt)
  },
  getLevelsPerModule(uuid,lang, companyId = null)
  {
    const opt = {
      params: {
        lang: lang
      }
    }
    if(companyId) opt.params.companyId = companyId
    return apiClient.get(`awareness/cga_modules/${uuid}/levels-per-module`, opt)

  },
  assignModuleToLevel(cga_level_uuid, cga_module_uuid, order, companyId = null) {
    const opt = { params : {} }
    const payload = {
      cga_level_uuid: cga_level_uuid,
      cga_module_uuid: cga_module_uuid,
      order: order
    }
    if(companyId) opt.params.companyId = companyId
    return apiClient.post(`awareness/cga_level_modules/`, payload, opt)
  },
  getUserLevels(accountId, lang, companyId = null) {
    const opt = {
      params: {
        accountId: accountId,
        lang: lang,
      }
    }
    if(companyId) opt.params.companyId = companyId
    return apiClient.get(`awareness/cga_account_levels`, opt)
  },
  createLevel(name, description, language, owner_uuid = null, service =null, companyId = null) {
    const opt = { params : {} }
    const payload = {
      name: name,
      description: description,
      language: language,
      owner_uuid: owner_uuid,
      service_id: service
    }
    if(companyId) opt.params.companyId = companyId
    return apiClient.post(`awareness/cga_levels/`, payload, opt)
  },
  deleteLevel( uuid, companyId = null) {
    const opt = { params : {} }
    if(companyId) opt.params.companyId = companyId
    return apiClient.delete(`awareness/cga_levels/${uuid}`, opt)
  },
  getLevelCompanies(cga_level_uuid, companyId = null) {
    const opt = { params: {} }
    if(companyId) opt.params.companyId = companyId
    return apiClient.get(`awareness/cga_levels/${cga_level_uuid}/companies?countRegistrations`, opt)
  },
  assignLevelToCompany(availability_mode, instant_release, certificate, propedeutic, company_uuid, cga_level_uuid, tenant, blocked_by = null, syncronize_users = null, orgs = null) {
    const payload = {
      tenant: tenant,
      availabilityMode: availability_mode,
      instantRelease: instant_release,
      areModulesPreparatory: propedeutic,
      companyId: company_uuid,
      cgaLevelUuid: cga_level_uuid,
      certificate: certificate,
      assignToThosePresent: syncronize_users,
      orgs: orgs
    }
    if(blocked_by)
    {
      payload.blockedByLevelUuid = blocked_by
    }
    return apiClient.post(`awareness/assign_level_to_company/`, payload)
  },
  assignLevelToUser(account_uuid, company_uuid, tenant, cga_level_uuid, instant_release, certificate, availability, propedeutic, blockedByLevelUuid = null) {
    const payload = {
      instantRelease: instant_release,
      companyId: company_uuid,
      tenant: tenant,
      areModulesPreparatory: propedeutic,
      certificate: certificate,
      availabilityMode: availability,
    }
    if(blockedByLevelUuid) payload.blockedByLevelUuid =  blockedByLevelUuid
    return apiClient.post(`awareness/level/${cga_level_uuid}/account/${account_uuid}`, payload)
  },
  removeLevelFromCompany(company_uuid, cga_level_uuid) {
    return apiClient.delete(`awareness/company/${company_uuid}/level/${cga_level_uuid}`)
  },
  hardDeleteLevelUser(account_uuid, level_uuid, tenant)
  {
    const payload = {
      tenant: tenant,
    }
    return apiClient.post(`awareness/level/${level_uuid}/account/${account_uuid}/hard-delete`, payload)
  },
  softDeleteLevelUser(account_uuid, level_uuid, company_uuid)
  {
    const payload = {
      companyId: company_uuid,
    }
    return apiClient.post(`awareness/level/${level_uuid}/account/${account_uuid}/soft-delete`, payload)
  },
  getCgaType() {
    return apiClient.get(`awareness/cga_type`)
  },
  updateCgaType( cga_type_id, payload ) {
    return apiClient.put(`awareness/cga_type/${cga_type_id}`, payload)
  },
  /**
   * releaseContent tutti i parametri sono obbligatori
   * @param company_uuid
   * @param service_id
   * @param release_date nel formato Y-m-d H:i:s
   * @returns json
   */
  releaseContent(company_uuid, service_id, release_date) {
    let payload = {
      companyId: company_uuid,
      release_date: release_date,
      service_id:service_id
    }
    return apiClient.post(`awareness/release_company_content`, payload)
  },
  uploadReservedAreaContent(company_uuid, payload, callbackProgress=null) {
    const opt = {
      onUploadProgress: callbackProgress,
      headers: { 'Content-Type': 'multipart/form-data;' }
    }
    return apiClient.post(`awareness/company/${company_uuid}/upload_reserved_area_content`, payload, opt)
  },
  switchModulesOrder(pathUuid, firstModuleUuid, secondModuleUuid) {
    let payload = {
      'cga_level_uuid': pathUuid,
      'cga_first_module_uuid': firstModuleUuid,
      'cga_second_module_uuid': secondModuleUuid
    }
    return apiClient.post(`awareness/cga_level_modules/switch-modules`, payload)
  },
  removeModuleFromPath(pathUuid, moduleUuid) {
    return apiClient.delete(`awareness/cga_levels/${pathUuid}/cga_modules/${moduleUuid}`)
  },
  getCompanyCertificatePreview( company_uuid, payload ) {
    return apiClient.post(`awareness/company/${company_uuid}/certificate_preview`, payload, {
      responseType: "blob"
    })
  },
  addNextActivityInPath(cga_level_uuid, moduleToAddUuid, afterModuleUuid) {
    const payload = {
      moduleToAddUuid : moduleToAddUuid,
      afterModuleUuid : afterModuleUuid
    }
    return apiClient.put(`awareness/cga_level_modules/${cga_level_uuid}/modules`, payload)
  },
  getCountRegistrationsPerCompany(cga_level_uuid) {
    return apiClient.get(`awareness/cga_registrations/${cga_level_uuid}/companies-registrations`)
  },
  getCertificatesByUser(account_uuid, lang, context = null, sortKey = null, sortDir = null) {
    const opt = {
      params: {
        lang: lang
      }
    }
    if(context){
      opt.params.page = context.currPage
      opt.params._limit = context.perPage
      opt.params.paginate = true
    }

    if (sortDir) opt.params.sortDir = sortDir
    if (sortKey) opt.params.sortKey = sortKey

    return apiClient.get(`awareness/account/${account_uuid}/certificates`,opt)
  },
  downloadUserCertificate(account_uuid, companyId, assert_uuid) {
    const opt = {
      responseType: 'blob',
      params: {
        companyId: companyId,
        assert_uuid: assert_uuid
      }
    }
    return apiClient.get(`awareness/account/${account_uuid}/certificate-download`,opt)
  },
  getUserCertificatesByCompany(account_uuid, companyId, lang = null, team = null, tags = null)
  {
    const opt = {
      params : {
        lang: lang,
        ... team && {team},
        ... tags && {tags}
      }
    }
    return apiClient.get(`awareness/company/${companyId}/account/${account_uuid}/certificates`,opt)
  },
  downloadUserCertificateByCompany(account_uuid, companyId, assert_uuid)
  {
    const opt = {
      responseType: 'blob',
      params: {
        assert_uuid: assert_uuid
      }
    }
    return apiClient.get(`awareness/company/${companyId}/account/${account_uuid}/certificate-download`,opt)
  }
}
