<template>
  <div class="support-wrapper">
    <span><em>{{ $t('Support.Copyright', {currentYear: $dateTime.now().toFormat('yyyy')}) }}</em></span>
    <span>{{ $t('Support.Address') }}</span>
    <div class="mt-5">
      <span>{{ $t('Support.Mail') }} <a :href="mailTo">{{ placeholderMailTo }}</a></span>
    </div>
  </div>
</template>

<script>
import {SupportEmail} from "@/common/constants";

export default {
  data() {
    return {
      display: false
    };
  },
  computed: {
    mailTo() {
      return 'mailto:' + this.getCompanySupportMailInfo();
    },
    placeholderMailTo() {
      return this.getCompanySupportMailInfo();
    }
  },
  methods: {
    getCompanySupportMailInfo() {
      return this.$company.company_options?.support_address?.value || SupportEmail;
    }
  }
};
</script>

<style lang='scss' scoped>
.support-wrapper {
  @apply bg-custom-primary text-custom-contrastPrimary mb-10 flex flex-col p-8;
  & a {
    @apply underline
  }

  & span {
    @apply text-sm
  }
}
</style>