import Vue from 'vue'
import router from '@/router/router'


const methods = {
  jsonToCsvParser: function(items) {
    // Retrieve header attributes and join them
    const header = Object.keys(items[0]);
    const headerString = header.join('|');

    // Handle null or undefined values
    const replacer = (key, value) => value ?? '';
    const rowItems = items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join('|'));

    // Join header and body, and break into separate lines
    const csv = [headerString, ...rowItems].join('\r\n');

    return csv;
  },
  downloadBlob: function(response, filename=null, isJson=false) {

    if (!filename) {
      let headerLine = response.headers['content-disposition']
      let startFileNameIndex = headerLine.indexOf('filename=') + 9
      filename = headerLine.substring(startFileNameIndex).trim()
    }

    console.log(response.headers)
    let fileLink = document.createElement('a')
    if(isJson) {
      const json = this.jsonToCsvParser(response.data);
      fileLink.href = window.URL.createObjectURL(new Blob([json], { type: 'application/json' }));
    } else {
      fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
    }
    fileLink.setAttribute('download', filename)

    document.body.appendChild(fileLink)
    fileLink.click()
    URL.revokeObjectURL(fileLink.href)
  },
  fillYearLabelArray(existingArray = []) {
    let len = existingArray.length

    if (len === 0)
      existingArray.push(Vue.prototype.$dateTime.now().toFormat('MMyyyy')) && len++

    if (len < 12)
      for (let i = 1; i <= 12 - len; i++)
        existingArray.push(Vue.prototype.$dateTime
            .fromFormat(existingArray[len - 1], 'MMyyyy')
            .plus({ month: i })
            .toFormat('MMyyyy'))

    return existingArray.map((m) => Vue.prototype.$dateTime
      .fromFormat(m, 'MMyyyy')
      .toFormat('LLL yy', { locale: router.app.$i18n.locale }))
  },
  padTo(arr = [], desiredLength = 12) {
    let len = arr.length

    if (len < desiredLength)
      for (let i = 1; i <= desiredLength - len; i++)
        arr.push('')

    return arr
  },
  maxDateFromArray(array) {
    array = array.filter(e => !!e)

    return array.length > 0
      ? Vue.prototype.$dateTime
        .max(...array.map(e => Vue.prototype.$dateTime.fromFormat(e, 'yyyy-MM-dd HH:mm:ss')))
        .toFormat('yyyy-MM-dd HH:mm:ss')
      : null
  },
  prependNullToLength(array, desiredLength) {
    let res = array
    for (let i = 0; i < desiredLength - array.length; i++)
      res = [null, ...res]
    return res
  },
  /**
   * Split the given string by the given pattern, remove the empty elements, all surrounding empty spaces and return unique and sorted values
   * @param {*} str
   * @param {*} pattern
   * @returns
   */
  splitString(str, pattern=',') {
    return str? [...new Set(str.split(new RegExp("\\s*" + pattern + "\\s*")).filter(d=>d))].sort() : [];
  },
  /**
   * Validate if the given role is child or equal as the base role
   * @param {*} role - source role
   * @param {*} baseRole - base role
   */
  isRole(role, baseRole) {
    return role && baseRole && (role+'').startsWith(baseRole);
  },
  /**
   * Role can be base role (csm, msp) and also a custom role (csm_21, msp_3). Retrieve the base role of the given role
   * @param {*} role - custom or base role
   * @returns 
   */
  getBaseRole(role) {
    return (role + '').split('_')[0];
  },
  /**
   * Invokes an api/function that returns a promise and manage the error codes
   * @param {*} fn - api/function to invoke
   * @param {*} defaultErrorMsg - default error message to be shown
   * @param  {...any} params - api/function parameters passed as parameters of the current function (for ex: invokeFn(api, 'General error message', companyId, accountId, orgName))
   */
  invokeFn(fn, defaultErrorMsg, ...params) {
    Vue.prototype.$eventBus.$emit('loading', true);
    return fn(...params)
      .catch(error => {
        const errResponse = error.response?.data?.error || {};
        let msg = errResponse.code? router.app.$i18n.tc('Error.'+errResponse.code, 0, errResponse.params || {}) : defaultErrorMsg;
        // show error message if there is a locale for the given code or if has been passed a default message
        if (msg) {
          Vue.prototype.$eventBus.$emit('show-alert', { title: msg, variant: 'danger' });
        }
        throw error;
      })
      .finally(() => {
        Vue.prototype.$eventBus.$emit('loading', false);
      })
  },

  /**
   * UrlPathname return pathname from url or empty string
   * @param {*} urlString
   */
  urlPathname(urlString)
  {
    try {
      return new URL(urlString).pathname;
    } catch(error) {
      return ''
    }
  },
  roundToDecimalPlaces (num, precision) {
    let multiplier = Math.pow(10, precision);
    return Math.round(num*multiplier) / multiplier;
  },
}

export default methods