<template>
  <transition name="fade">
    <div v-if="dismissCountDown" class="white-overlay">
      <div class="alert-wrapper">
        <div
          :class="{
            alert: true,
            'v-success': type === 'success',
            'v-danger': type === 'danger',
            'v-info': type === 'info',
            'v-warning': type === 'warning'
          }"
          role="alert"
        >
          <div v-if="iconName" class="alert-icon">
            <Icon
              :name="iconName"
              :size="iconSize"
              :style="{
                padding: iconSize ? '3px' : undefined,
                margin: iconSize ? '0' : undefined,
              }"
            />
          </div>
          <div class="alert-content">
            <Txt
              as="h4"
              type="none"
              small
              weight="bold"
              class="alert-title"
              no-margin
              :style="{
                lineHeight: iconSize ? `${iconSize + 6}px` : undefined,
              }"
            >
              {{ title }}
            </Txt>
            <Txt v-if="$slots.default" small class="alert-text">
              <slot />
            </Txt>
            <Link v-if="link" class="alert-link">{{ linkLabel }}</Link>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String, // 'success' | 'danger' | 'info'
      default: undefined,
    },
    iconSize: {
      type: Number,
      default: undefined,
    },
    link: {
      type: String,
      default: undefined,
    },
    linkLabel: {
      type: String,
      default: 'Dettagli',
    },
    dismissSecs: {
      type: [Number, Boolean],
      default: 5
    }
  },
  data: function () {
    return {
      dismissCountDown: this.dismissSecs == true ? true : 0,
      iconName: "checkSmall"
    }
  },
  methods: {
    showAlert() {
      this.iconName = this.type === 'success'? "checkSmall" : (this.type === 'danger'? "alert" : "info");
      this.dismissCountDown = this.dismissSecs;
      setTimeout(() => {
        // Hide alert after duration has passed
        this.dismissCountDown = 0;
      }, this.dismissSecs * 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  @apply flex items-start w-full p-4;
  @apply bg-primary-500 text-white;
  @apply shadow-md;

  &.v-success {
    @apply bg-green-700;
  }

  &.v-danger {
    @apply bg-red-900;
  }

  &.v-info {
    @apply bg-soft-blue-700;
  }

  &.v-warning {
    @apply bg-yellow-500;
  }
}

.alert-icon {
  @apply shrink-0;
  @apply flex items-center justify-center;
  @apply rounded-full bg-white;
  @apply text-primary-300 text-4xl;
  @apply mr-4;

  .v-success & {
    @apply text-green-700;
  }
  .v-danger & {
    @apply text-red-700;
  }
  .v-info & {
    @apply text-soft-blue-700;
  }

  &.v-warning {
    @apply text-yellow-500;
  }

  .icon {
    @apply -m-1;
  }
}

.alert-content {
  @apply flex-1 flex flex-wrap;
}

.alert-title {
  @apply flex-1;
  @apply mt-px; // font adjustment
}

.alert-text {
  @apply flex-grow basis-full;
  @apply mt-2;

  & + .alert-link {
    @apply mt-5 ml-0;
  }
}

.alert-link {
  @apply shrink-0;
  @apply ml-4;

  .alert & {
    @apply text-white text-sm font-medium;
  }
}

.white-overlay {
  @apply z-[999] top-0 w-3/4 mx-[12.5%] fixed;
}

.alert-wrapper {
  @apply w-auto relative;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-62px);
}
</style>
