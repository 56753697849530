<template>
    <span>
        <Button ref="filtersBtn" :disabled="!campaignFilterOptions.length && !orgsListFilter.length" class="ml-2" @click="modalFilters=true"> {{ $t('Reporting.Filters') }}</Button>

        <div v-if="Object.keys(defaultTagsFilters).length" class="col-span-4 md:col-span-3 applied-filters mt-4">
            <span v-for="(t, index_t) in Object.keys(defaultTagsFilters)" :key="index_t">
                <Tag class="mr-2"> {{ orgDisplayName(t)+' : '+(defaultTagsFilters[t] || tagNotAssigned)}}</Tag>
            </span>
        </div>
        <div class="col-span-4 md:col-span-3 applied-filters mt-4">
            <span v-show="selCampaignFilter || Object.keys(selTagsFilters).length">
                <Tag class="mr-2" show-remove @remove="removeAllFilters">{{$t('Reporting.RemoveFilters')}}</Tag>
            </span>
            <span v-if="selCampaignFilter && selCampaignFilter!=RANGE_CAMPAIGNS">
                <Tag class="mr-2" show-remove @remove="campaignFilter=''; filter()">{{$t('Reporting.Campaign')+' : ' + campaignFilterName}}</Tag>
            </span>
            <span v-if="selCampaignFilter==RANGE_CAMPAIGNS">
                <Tag class="mr-2" show-remove @remove="campaignFilter='';filter()">{{rangeCampaignTag}}</Tag>
            </span>
            <span v-for="(t, index_t) in Object.keys(selTagsFilters)" :key="index_t">
                <Tag v-if="selTagsFilters[t] !=''" class="mr-2" show-remove @remove="filter(t)"> {{ orgDisplayName(t)+' : '+selTagsFilters[t] }}</Tag>
            </span>
        </div>
        <Modal
            :open.sync="modalFilters"
            :title="$t('Reporting.Filters')"
            :trigger="$refs.filtersBtn"
            footer-alignment="right"
            with-close-button
        >
            <div class="w-full text-primary-500 px-4 py-4 text-sm">
                <div v-if="campaignFilterOptions.length" class="grid grid-cols-3 gap-6 mb-5">
                    <FormGroup :label="$t('Reporting.Campaign')" html-for="campaignFilter" no-margin>
                        <Select
                            id="campaignFilter"
                            v-model="campaignFilter"
                            :options="campaignFilterOptions"
                            @input="$forceUpdate()"
                        />
                    </FormGroup>
                    <div class="col-span-2 campaignRange">
                        <div v-if="campaignFilter==RANGE_CAMPAIGNS" class="flex flex-row items-center gap-3 mt-6">
                            <FormGroup html-for="startCampaignOffsetSelect" :label="$t('Dashboard.Charts.FromCampaign')" no-margin horizontal>
                                <Select id="startCampaignOffsetSelect" v-model="startCampaignOffset" :options="startCampaignFilterOptions" @input="$forceUpdate()"/>
                            </FormGroup>
                        </div>
                    </div>
                </div>

                <div v-if="Object.keys(orgsListFilter).length" class="grid grid-cols-3 gap-6">
                    <FormGroup v-for="org in orgsListFilter" v-bind:key="org" :label="orgsValues[org].label || org" no-margin :html-for="org">
                        <Select 
                            v-if="org in defaultTagsFilters || (isTeamLeader && org === orgTeam)" 
                            :id="org" 
                            :value="(isTeamLeader && $profile.team) || defaultTagsFilters[org] || tagNotAssigned"
                            :options="orgsValues[org].filteredOptions || orgsValues[org].options"
                            disabled />
                        <Select v-else
                            :id="org"
                            v-model="tagsFilters[org]"
                            :placeholder="orgDisplayName(org)"
                            :options="orgsValues[org].filteredOptions || orgsValues[org].options"
                            :disabled="org in defaultTagsFilters"
                            @input="$forceUpdate();getOrgs()"
                        />
                    </FormGroup>
                </div>
            </div>

            <template #footer>
                <Button variant="outline" @click="modalFilters = false">{{$t('Reporting.Cancel')}}</Button>
                <Button @click="modalFilters = false; filter()">{{$t('Reporting.ApplyFilters')}}</Button>
            </template>
        </Modal>
    </span>
</template>

<script>
import Vue from 'vue'
import companyService from '@/services/company.service.js'
import { MaxCampaignReport, Roles }from '@/common/constants'
const RANGE_CAMPAIGNS = 'rangeCampaigns'

export default {
    props: {
        companyId: {
            type: String,
            required: true
        },
        campaigns: {
            type: Array,
            default: () => []
        },
        maxCampaigns: {
            type: Number,
            default: MaxCampaignReport
        },
        orgs: {
            type: Object,
            default: null
        }
    },
    emits: ['filters'],
    data() {
        return {
            modalFilters: false,
            orgTeam: null,
            orgsValues: {},
            campaignFilter: '',
            selCampaignFilter: '',
            startCampaignOffset: 0,
            selStartCampaignOffset: 0,
            tagsFilters: {},
            selTagsFilters: {},
            defaultTagsFilters: this.$profile.filteredTags || {},
            tagNotAssigned: this.$t('Analytics.NA'),
            RANGE_CAMPAIGNS
        }
    },
    watch: {
        '$props.companyId': {
            handler: function () {
                this.orgsValues = {};
                this.tagsFilters = {};
                this.defaultTagsFilters = this.$profile.filteredTags || {};
                this.getOrgs();
            }, deep: true
        }
    },
    mounted() {
        if (this.orgs) this.updateOrgValues(this.orgs); 
        else this.getOrgs();
    },
    computed: {
        orgsListFilter(){
            return Object.keys(this.orgsValues).filter(tag=> this.orgsValues[tag].show && this.orgsValues[tag].value?.length)
        },
        campaignFilterOptions(){
            if (!this.campaigns.length) return [];

            let options = this.campaigns.toReversed().map( c => { return  { value : c.campaign_id, label: c.name} } )

            if (this.campaigns.length <= this.maxCampaigns) {
                options.unshift({ value:'', label: this.$t('Dashboard.Charts.AllCampaigns') })
            } else {
                options.unshift({ value:RANGE_CAMPAIGNS, label: this.$t('Dashboard.Charts.RangeCampaigns', {param: this.maxCampaigns}) })
                options.unshift({ value:'', label: this.$t('Dashboard.Charts.LastCampaigns', {param: this.maxCampaigns}) })
            }

            return options
        },
        startCampaignFilterOptions() {
            if (!this.campaigns.length) return [];
            
            let options = []
            const campaigns = this.campaigns.toReversed();
            for (let i = 0; i <= campaigns.length - this.maxCampaigns; i++) {
                options.push({value: campaigns.length - this.maxCampaigns - i, label: campaigns[i].name})
            }
            return options;
        },
        campaignFilterName(){
            return this.selCampaignFilter && this.selCampaignFilter != RANGE_CAMPAIGNS && this.campaigns.find(c => c.campaign_id == this.selCampaignFilter)?.name || '';
        },
        rangeCampaignTag() {
            const startIndex = this.selStartCampaignOffset + this.maxCampaigns - 1;
            return this.campaigns[startIndex].name + ' - ' + this.campaigns[startIndex - this.maxCampaigns + 1].name;
        },
        isTeamLeader(){
            return this.$profile.hasRoles(Roles.Teamleader) && this.$profile.team;
        }
    },
    methods: {
        async getOrgs(){
            try {
                // set org team for the company if the user is a teamleader
                if (this.isTeamLeader && !this.orgTeam) {
                    const companyOrgs = this.$profile.companies.find(c=>c.company_id===this.companyId)?.tags?.orgs || {};
                    for (let org in companyOrgs) if (companyOrgs[org].team) this.orgTeam = org;
                }

                // Retrieve tag filter with actual values
                let queryParams = Object.keys(this.tagsFilters)
                    .filter(el => !!this.tagsFilters[el])
                    .reduce((obj, key) => {
                        return {...obj, [key]: (this.tagsFilters[key] != this.tagNotAssigned)? this.tagsFilters[key] : null};
                    }, {});
                
                const result = await companyService.getTags(this.companyId, {tags: {...queryParams, ...this.$profile.filteredTags, ... this.isTeamLeader && {[this.orgTeam]: this.$profile.team}},  fullinfo: true, withNulls: true });
                this.updateOrgValues(result.data?.orgs || {});
            } catch (error) {
                console.error(error)
            }
        },
        updateOrgValues(orgs) {
            const isFirstUpdate = !Object.keys(this.orgsValues).length;
            const defaultOptions = [ {label: this.$t('Dashboard.Charts.All'), value: ''} ];
            let orgsArr = this.$getFlatTags(orgs)
            orgsArr.sort((a, b)=>a.order-b.order)

            orgsArr.forEach(org => {
                if (org.team) this.orgTeam = org.key;
                if (!org.value) org.value = [];
                
                const options = (org.value.length)? 
                    defaultOptions.
                        concat(org.value.includes(null)? [{label: this.tagNotAssigned, value: this.tagNotAssigned}] : []).
                        concat(org.value.filter((val) => val).map((val) => { return { label: val, value: val } })) 
                    : [];
                
                if (isFirstUpdate) {
                    Vue.set(this.orgsValues, org.key, org);
                    Vue.set(this.orgsValues[org.key], 'options', options);
                } else {
                    Vue.set(this.orgsValues[org.key], 'filteredOptions', options);
                }
            });
        },
        removeAllFilters() {
            this.campaignFilter = '';
            this.startCampaignOffset = 0;
            this.tagsFilters = {};
            this.filter();
        },
        filter(tag) {
            if (tag) delete this.tagsFilters[tag];
            
            // remove empty tag filters
            this.tagsFilters = Object.keys(this.tagsFilters)
                .filter(el => !!this.tagsFilters[el])
                .reduce((obj, key) => {
                    return {...obj, [key]: this.tagsFilters[key]};
                }, {});

            // update selected tags
            this.selCampaignFilter = this.campaignFilter;
            this.selStartCampaignOffset = this.startCampaignOffset;
            this.selTagsFilters = Object.assign({}, this.tagsFilters);
            
            // emit filters event
            this.$emit('filters', { 
                campaignFilter: this.campaignFilter == RANGE_CAMPAIGNS? '' : this.campaignFilter,
                startCampaignOffset: this.campaignFilter == RANGE_CAMPAIGNS? this.startCampaignOffset : 0,
                tagsFilters: Object.keys(this.tagsFilters)
                    .reduce((obj, key) => {
                        return {...obj, [key]: (this.tagsFilters[key] != this.tagNotAssigned)? this.tagsFilters[key] : null};
                    }, {})
            });

            // reload tags
            this.getOrgs();
        },
        orgDisplayName(org) {
            if (!org || !this.orgsValues[org]) return ''
            return this.orgsValues[org].label || this.orgsValues[org].key;
        },
    }
}
</script>