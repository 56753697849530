<template>
	<AnalyticsCard layout="vertical" :title="title" :chart-height="chartHeight" :showLoader="isLoading">
        <template #chart>
            <PieChart
                v-if="chart.datasets.length && chart.datasets[0].data.length"
                :datasets="chart.datasets"
                :labels="chart.labels"
                show-legend
                :dashed-lines="true"
                :aspect-ratio="aspectRatio"
                :chart-options="options"
                />
            <div
                v-if="!isLoading && chart.datasets.length && !chart.datasets[0].data.length"
                class="!min-h-[13rem] flex flex-col font-bold text-xl">
                <div class="m-auto"> <Txt bold>{{ $t('Dashboard.Charts.NoData') }}</Txt> </div>
            </div>
        </template>
        <template #menu-items>
            <MenuItem @click="setChartMode('device')">{{$t('Dashboard.Charts.Device')}}</MenuItem>
            <MenuItem @click="setChartMode('os')">{{$t('Dashboard.Charts.OS')}}</MenuItem>
            <MenuItem @click="setChartMode('browser')">{{$t('Dashboard.Charts.Browser')}}</MenuItem>
        </template>
    </AnalyticsCard> 
</template> 

<script>
import phishingService from '@/services/phishing.service.js'
import colors from '@/utils/colors'

export default {
  data () {
    return {
      isLoading:    false,
      data:         {},
      title:        this.$t('Dashboard.Charts.ClickRateByParam', { param: this.$t('Dashboard.Charts.Device') }),
      chartMode:    'device',
      options:      {},
      chart:        {
        labels:   [],
        datasets: []
      },
      chartOptions: {},
      counter:      0
    }
  },
  props: {
    apiFilter:   Object,
    chartHeight: Number,
    aspectRatio: Number
  },
  watch: {
    '$props.apiFilter': {
      handler () {
        this.loadData()
      },
      deep: true
    }
  },
	mounted(){
        this.loadData()
	},
	methods : {
        loadData() {
            this.data = {}
            this.chartOptions = {
                device :  {
                    title: this.$t("Dashboard.Charts.ClickRateByParam", {param : this.$t('Dashboard.Charts.Device')}),
                    chartConfig : {
                        labels: [],
                        datasets: [
                            {
                            label: this.$t('Dashboard.Charts.Device'),
                            backgroundColor: [
                                colors.green[500], colors['soft-blue'][500], colors.red[500] , colors.violet[500]
                            ] ,
                            clicked : [],
                            data: [],
                            },
                        ],
                    }
                },
                os :  {
                    title: this.$t("Dashboard.Charts.ClickRateByParam", {param : this.$t('Dashboard.Charts.OS')}),
                    chartConfig : {
                        labels: [],
                        datasets: [
                            {
                            label: this.$t('Dashboard.Charts.OS'),
                            backgroundColor: [
                                colors.green[500], colors['soft-blue'][500], colors.red[500],  colors.violet[500], colors.accent[500] , colors.primary[500] ,
                                colors.violet[300], colors.red[300], colors.primary[300] ,  colors['soft-blue'][300], colors.accent[300] , colors.green[300],
                            ] ,
                            clicked : [],
                            data: [],
                            },
                        ],
                    }
                },
                browser :  {
                    title: this.$t("Dashboard.Charts.ClickRateByParam", {param : this.$t('Dashboard.Charts.Browser')}),
                    chartConfig : {
                        labels: [],
                        datasets: [
                            {
                                label: this.$t('Dashboard.Charts.Browser'),
                                backgroundColor: [
                                    colors.green[500], colors['soft-blue'][500], colors.red[500],  colors.violet[500], colors.accent[500] , colors.primary[500] ,
                                    colors.violet[300], colors.red[300], colors.primary[300] ,  colors['soft-blue'][300], colors.accent[300] , colors.green[300],
                                ] ,
                                clicked : [],
                                data: []
                            },
                        ],
                    }
                }
            }
            this.setChartMode('device')
        },
        async setChartMode(mode){
            try {
                this.counter++;
                this.isLoading = true;
                
                await this.transformData(mode);
                this.title = this.chartOptions[mode].title
                this.chart = this.chartOptions[mode].chartConfig
                this.setOptions()
            } finally {
                if (this.counter > 0) this.counter--;
                
                if (!this.counter) this.isLoading = false;
            }
        },
		async transformData(mode){
            //// Click rate by Device
            // TODO: check how to import phished_device values
            const devices = {
                1 : 'Other',
                2 : 'Desktop',
                3 : 'Tablet',
                4 : 'Mobile',
            }

			let totalClicks = 0;
            if (mode == 'device' && !this.data.device) {
                const res = await phishingService.getCompanySummary(this.$currentCompany.company_id, {code: 'device', ...this.apiFilter});
                this.data.device = res.data || [];
                this.data.device.forEach(d => { totalClicks+= d.clicked})

                for(const c of this.data.device){
                    const label = 'Analytics.Devices.'+devices[c.phished_device]
                    this.chartOptions.device.chartConfig.labels.push(this.$t(label))
                    this.chartOptions.device.chartConfig.datasets[0].data.push( totalClicks == 0 ? 0 : ((c.clicked/totalClicks)*100).toFixed(1) )
                    this.chartOptions.device.chartConfig.datasets[0].clicked.push( c.clicked )
                }
            }
            

            //// Click rate by OS
            totalClicks = 0;

            if (mode == 'os' && !this.data.os) {
                const res = await phishingService.getCompanySummary(this.$currentCompany.company_id, {code: 'os', ...this.apiFilter});
                this.data.os = res.data || [];
                this.data.os.forEach(d => { totalClicks+= d.clicked})

                for(const c of this.data.os){
                    this.chartOptions.os.chartConfig.labels.push(c.phished_os)
                    this.chartOptions.os.chartConfig.datasets[0].data.push(totalClicks == 0 ? 0 : ((c.clicked/totalClicks)*100).toFixed(1) )
                    this.chartOptions.os.chartConfig.datasets[0].clicked.push( c.clicked )
                }
            }

            //// Click rate by Browser
            totalClicks = 0;
            if (mode == 'browser' && !this.data.browser) {
                const res = await phishingService.getCompanySummary(this.$currentCompany.company_id, {code: 'browser', ...this.apiFilter});
                this.data.browser = res.data || [];
                this.data.browser.forEach(d => { totalClicks+= d.clicked})

                for(const c of this.data.browser){
                    this.chartOptions.browser.chartConfig.labels.push(c.phished_browser)
                    this.chartOptions.browser.chartConfig.datasets[0].data.push(totalClicks == 0 ? 0 : ((c.clicked/totalClicks)*100).toFixed(1) )
                    this.chartOptions.browser.chartConfig.datasets[0].clicked.push( c.clicked )
                }
            }
		},
		setOptions(){
			this.options = {
				plugins: {
                    tooltip: {
                        callbacks : {
                            label: (tooltipItem) => {
                                return `${tooltipItem.label}: ${tooltipItem.formattedValue} %`;
                            },
                            afterBody: (tooltipItem) => {
                                let body = []
                                // let datasetIndex = tooltipItem[0].datasetIndex;
                                let index = tooltipItem[0].dataIndex;
    
                                let clicked = tooltipItem[0].dataset.clicked[index];
                                
                                body.push("");
                                body.push(this.$t('Dashboard.Charts.TotalClicks') + ": " + clicked);
                                
                                return body;
                            }
                        }
                    }
                }
			}
		}
	}
};
</script>