<template>
  <span class="icon">
    <svg v-if="iconConfig" :width="size" :height="size" :aria-label='alt' role="img" :viewBox="iconConfig.viewBox" fill="currentColor">
      <path :d="iconConfig.path"></path>
    </svg>
  </span>
</template>

<script>
import { icons, iconsBrand } from '@/assets/icons'

const setIcons = {
  icons,
  brand: iconsBrand,
}

export default {
  props: {
    set: {
      type: String,
      default: 'icons',
    },
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: '1em',
    },
    alt: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    iconConfig() {
      return setIcons[this.set][this.name]
    },
  },
}
</script>

<style lang="scss" scoped>
.icon {
  @apply inline-block;
}
</style>
