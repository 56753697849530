<template>
    <AnalyticsCard layout="vertical" :title="title" :show-loader="isLoading">
        <template #chart> 
            <div class="grid gap-4">
                <ProgressCard :title="$t('Dashboard.Charts.AttacksSent')" :value="stats.sent || 0" icon="attackSent" color="blue" />
                <ProgressCard :title="$t('Dashboard.Charts.ClickedAttacks')" :value="stats.clicked || 0" icon="attackClicked" color="blue" />
                <ProgressCard v-if="reportingEnabled" :title="$t('Dashboard.Charts.ReportedAttacks')" :value="stats.reported || 0" icon="attackReported" color="blue" />
                <ProgressCard :title="$t('Dashboard.Charts.Targets')" :value="stats.targets || 0" icon="attackTargets" color="blue" />
            </div>
        </template>
        <template v-if="smsEnabled" #menu-items>
            <MenuItem @click="updateStats(TEMPLATE_TYPE_GLOBAL)">{{ $t('Dashboard.Charts.StatsGlobal') }}</MenuItem>
            <MenuItem @click="updateStats(TEMPLATE_TYPE_SMS)">{{ $t('Dashboard.Charts.StatsSms') }}</MenuItem>
            <MenuItem @click="updateStats(TEMPLATE_TYPE_EMAIL)">{{ $t('Dashboard.Charts.StatsEmail') }}</MenuItem>
        </template>
    </AnalyticsCard>
</template> 

<script>
import phishingService from '@/services/phishing.service.js';
import { TemplateType } from '@/common/constants';

export default {
    props: {
        apiFilter: Object,
        companyData: Object
	},
	data() {
		return {
            datasets : [],
            stats: {
                clicked: 0,
                reported: 0,
                targets: 0,
                sent: 0
            },
            title: this.$t('Dashboard.Charts.GlobalStats'),
            type: null,
            globalStatsData: [],
            targetStatsData: [],
            isLoading: false,
            counter: 0
		}
	},
    computed: {
        smsEnabled() {
            return this.companyData? !!this.companyData.sms_enabled : false;
        },
        reportingEnabled() {
            return this.companyData? !!this.companyData.reporting_enabled : false;
        }
    },
    watch: {
        apiFilter: async function () {
            await this.fetchData();
            this.updateStats();
        }
    },
    async mounted(){
        await this.fetchData();
        this.updateStats();
	},
    created() {
        this.TEMPLATE_TYPE_SMS = 'sms';
        this.TEMPLATE_TYPE_EMAIL = 'email';
        this.TEMPLATE_TYPE_GLOBAL = 'global';
    },
    methods: {
        async fetchData() {
            this.counter++;
            this.isLoading = true;
            let targetStats = await phishingService.getNumAttackedTargets(this.$currentCompany.company_id, this.apiFilter) || [];
            this.targetStatsData = targetStats.data;

            this.assignAttackedTarget();

            let globalStats = await phishingService.getCompanyGlobalStats(this.$currentCompany.company_id, this.apiFilter) || [];
            this.globalStatsData = globalStats.data;
    
            let total = {};
            if(this.globalStatsData && this.globalStatsData.length > 1) {
                total = Object.keys(this.globalStatsData[0]).reduce((a, k) => {
                    a[k] = this.globalStatsData[0][k] + this.globalStatsData[1][k];
                    return a;
                }, {});
            } else {
                total = this.globalStatsData[0] || {};
            }

            this.datasets[this.TEMPLATE_TYPE_EMAIL] = this.globalStatsData.filter((t) => t.type === TemplateType.Email)[0] || {};
            this.datasets[this.TEMPLATE_TYPE_SMS] = this.globalStatsData.filter((t) => t.type === TemplateType.SMS)[0] || {};
            this.datasets[this.TEMPLATE_TYPE_GLOBAL] = this.smsEnabled ? total : this.datasets[this.TEMPLATE_TYPE_EMAIL] || {};

            if (this.counter > 0) this.counter--;
            if (!this.counter) this.isLoading = false;
        },
        updateStats(value) {
            let templateType = value || this.TEMPLATE_TYPE_GLOBAL;
            this.title = this.$t('Dashboard.Charts.Stats' + templateType.charAt(0).toUpperCase() + templateType.slice(1));

            this.type = templateType == this.TEMPLATE_TYPE_SMS ? TemplateType.SMS : (templateType == this.TEMPLATE_TYPE_EMAIL ? TemplateType.Email : null);
            
            const percentageClickRate = (100 * this.datasets[templateType].clicked/this.datasets[templateType].sent).toFixed(1);
            const clickRate =  percentageClickRate > 0 && !isNaN(percentageClickRate) ? percentageClickRate + "%" + "("+this.datasets[templateType].clicked+")" : "0% (0)";

            const percentageReported = (100*this.datasets[templateType].reported/this.datasets[templateType].sent).toFixed(1);
            const reported =  percentageReported > 0 && !isNaN(percentageReported) ? percentageReported + "%" : "0%";

            this.stats.sent = this.datasets[templateType].sent;
            this.stats.reported = reported;
            this.stats.clicked = clickRate;

            this.assignAttackedTarget();
        },
        assignAttackedTarget() {
            if(this.type) {
                let item = this.globalStatsData.filter(elem => { return elem.type == this.type; });
                this.stats.targets = item && item[0]? item[0].targets : 0;
            } else {
                this.stats.targets = this.targetStatsData? this.targetStatsData.reduce(function(prev, cur) { return prev + cur.targets; }, 0) : {};
            }
        }
    }
};
</script>