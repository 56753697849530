<template>
  <component
      :is="component"
      class="profile-menu"
      :placement="menuPlacement"
      :to="to"
      variant="unstyled"
      @click="$emit('click')"
  >
    <img v-if="!$slots.default" class="profile-menu-avatar"/>
    <template #button>
      <button class="profile-menu-toggle">
        <div v-if="displayName" class="profile-menu-avatar">{{ displayName }}</div>
        <span v-if="$profile.team" class="profile-menu-team-name">{{ $profile.team }}</span>
        <Icon v-if="showIndicator" name="chevronDown" :size="24"/>
      </button>
    </template>
    <slot/>
  </component>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: undefined,
    },
    displayName: {
      type: String,
      default: undefined
    },
    menuPlacement: {
      type: String, // See Menu.vue
      default: undefined,
    },
    showIndicator: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    component() {
      return this.to ? 'Link' : this.$slots.default ? 'Menu' : 'button';
    }
  },
};
</script>

<style lang="scss" scoped>
.profile-menu {
  @apply inline-flex;
  @apply bg-white;
  @apply border border-primary-100;
}

.profile-menu-toggle {
  @apply inline-flex items-center;
}

.profile-menu-avatar {
  @apply w-10 h-10;
  //@apply bg-soft-blue-100;
  @apply bg-custom-primary;
  @apply text-custom-contrastPrimary;
  @apply leading-[40px];
  @apply origin-center object-cover;
}

.profile-menu-team-name {
  @apply border-l border-r;
  @apply h-10;
  @apply flex items-center px-4;
  @apply text-xs capitalize font-bold;
}
</style>